<template>
    <div class="title-bar">
        <div class="title-bar-logo">
            <!-- <img src="~@img/logo_tnhw.png" alt="" srcset=""> -->
            <!-- <img src="~@img/logo_hwsd.png" alt="" srcset=""> -->
            <img :src="logo.logoLeft" alt>
        </div>
        <div class="title-bar-title">
            <!-- <img src="~@img/logo_hwsd.png" alt="" srcset=""> -->
            <!-- <img src="~@img/mmexport.png" alt="" srcset=""> -->
            <img :src="logo.logoRight" alt>
        </div>

        <div class="title-bar-contene">
            <slot />
        </div>
    </div>
</template>

<script>

import { getLogo } from '@/api/api.js';
export default {
    data() {
        return {
            logo: {}
        }
    },
    mounted() {
        this.getLogo();
    },
    methods: {
        async getLogo() {
            this.logo = await getLogo();
        }
    }
}
</script>

<style lang="less" scoped>
.title-bar {
    height: 55px;
    display: flex;
    align-items: center;
}

.title-bar-logo,
.title-bar-title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    // padding: 6px 14px;
    padding: 10px 20px;
    // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.title-bar-contene {
    flex: 1;
    // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    height: 100%;
    // display: flex;
}
</style>