/*
 * @Author: sxw s9x9w9@163.com
 * @Date: 2022-10-14 09:36:39
 * @LastEditors: sxw s9x9w9@163.com
 * @LastEditTime: 2023-02-20 11:06:50
 * @FilePath: \goodThing\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';

const bus = new Vue();

import '@/utils/http/request';
// 时间格式化
import format from '@/utils/format';
import './components/index';

import 'ant-design-vue/dist/antd.css';

import { DatePicker, message, ConfigProvider, Spin } from 'ant-design-vue';


// 全局样式
import '@/assets/style/index.less';
import 'swiper/css/swiper.min.css';

// import 'swiper/dist/js/swiper.min'
// import 'swiper/swiper.min.css'; // 轮播UI样式
// import 'swiper/swiper-bundle.css'; // 轮播UI样式
import swiper from 'vue-awesome-swiper'; // 轮播UI库

require('@/utils/instructions/');


// if (token) Vue.prototype.$http.token = token;
// else Vue.prototype.$http.token = "token";

Vue.config.productionTip = false;
Vue.use(format);
Vue.use(swiper);
Vue.use(DatePicker);
Vue.use(ConfigProvider);
Vue.use(Spin);
global.bus = bus;
Vue.prototype.$message = message;
message.config({
    top: `120px`,
    duration: 2,
    maxCount: 3,
});
new Vue({
    router,
    render: h => h(App),
    mounted() {
        window.vue = this;
    }
}).$mount('#app')
