<template>
    <modal :value="value" v-on="$listeners">
        <div class="popup_content" @click="changeTitle()">
            <!-- 封面 -->
            <div class="good_cover_box">

                <div class="good_cover">
                    <img :src="priceMessage.mainImg" alt />
                </div>
                <div class="good_cover_out">
                    <div class="good_small_cover">
                        <!-- 图案展示 -->
                        <div class="pattern">
                            <img :src="priceMessage.textureImg" alt="图片损坏" />
                        </div>
                        <!-- 配件列表 -->
                        <div v-for="(item, index) in priceMessage.category" :key="index" class="detailimg">
                            <img :src="item.img" />
                            <p :title="item.name || '无'">{{ item.name || '无' }}</p>
                        </div>
                    </div>
                </div>
                <textarea class="remark" v-model="priceMessage.remarks" @blur="putArchives" @keyup.enter="putArchives"
                    placeholder="备注：" />
            </div>
            <!-- 描述 -->
            <div class="good_detail">
                <div class="good_title">
                    <input @click.stop v-if="changeName" v-focus @keyup.enter="changeTitle()" type="text"
                        v-model="priceMessage.title">
                    <h3 v-else>{{ priceMessage?.title || '无标题' }}</h3>
                    <span @click.stop="changeName = true">点我修改方案名称</span>
                </div>
                <!-- 材质 -->
                <div class="good_texture">
                    <p v-if="!priceMessage?.material?.length">暂无材质信息</p>
                    <p v-for="item, index in priceMessage.material" :key="index"><span>材质{{ index + 1 }}：
                            {{ item.name }}</span><span>{{ item.value ? item.value : '不可选' }}</span></p>
                </div>
                <!-- 工艺 -->
                <div class="good_technology">
                    <p v-if="!priceMessage?.workmanship?.length">暂无工艺信息</p>
                    <p v-for="item, index in priceMessage.workmanship" :key="index">
                        <span>工艺{{ index + 1 }}：{{ item.name }}</span>
                        <span>
                            <p>{{ item.value ? '自选色：' : '不可选' }}</p>
                            <div v-if="item.value">
                                <div v-for="color, index in JSON.parse(item.value)" :key="index"
                                    :style="{ 'background-color': color }">
                                </div>
                            </div>
                        </span>
                    </p>
                </div>
                <!-- 价格部分 -->
                <div class="good_content">
                    <table class="good_table">
                        <tr>
                            <th>
                                <div>品名</div>
                            </th>
                            <th>
                                <div>数量</div>
                            </th>
                            <th>
                                <div>单价</div>
                            </th>
                            <th>
                                <div>小计</div>
                            </th>
                        </tr>
                        <tr v-for="(item, index) in priceMessage.category" :key="index">
                            <td>
                                <div class="description" :title="item.name || '无'">{{ item.name || '无' }}</div>
                            </td>
                            <td>
                                <div class="table_count table_oval">
                                    <img :style="{ cursor: item.number <= 0 ? 'not-allowed' : '' }"
                                        @click="count('sub', item, index);" src="@img/icon/减.png" />
                                    <input style="flex-shrink: 0;width: 40px;" @input="amount(item, index)" min="1"
                                        type="number" v-model="item.number" value="1">
                                    <img @click="count('add', item, index);" src="@img/icon/加.png" />
                                </div>
                            </td>
                            <td class="overstep">
                                <!-- 价格1 -->
                                <div class="table_oval oval_disabled"
                                    :class="{ oval_current: item.number > 0 && item.number <= item?.referencePrice[0].number }">
                                    ≤{{ item.referencePrice[0].number }}件￥{{ item?.referencePrice[0].price || 0 }}/件
                                </div>

                                <!-- 价格2 -->
                                <div class="table_oval oval_disabled"
                                    :class="{ oval_current: item.number > item?.referencePrice[0].number && item.number < item?.referencePrice[1].number }">
                                    {{ item?.referencePrice[0].number + 1 }}~{{ item?.referencePrice[1].number - 1 }}件￥{{
                                            item?.referencePrice[1].price || 0
                                    }}/件</div>

                                <!-- 价格3 -->
                                <div class="table_oval oval_disabled"
                                    :class="{ oval_current: item.number >= item?.referencePrice[1].number }">
                                    ≥{{ item?.referencePrice[1].number }}件￥{{ item?.referencePrice[2].price || 0 }}/件
                                </div>
                            </td>
                            <td>
                                <p class="subtotal">{{ item.price ? `￥${item.price}` : '-' }}</p>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="good_msg">
                    <p>预计花费：￥{{ priceMessage.evaluation }}</p>
                </div>
                <div class="good_price">
                    <button @click="placeOrder">联系客服下单购买</button>
                    <button @click="$emit('input', false)">我再想想</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { getArchives, putArchives } from '@/api/api';

export default {
    props: {
        value: Boolean,
    },
    data() {
        return {
            messageName: '中国风浴室定制品6件套',
            changeName: false,/* 修改名称 */
            priceMessage: {},
            priceLoading: false,
            paramsData: {
                "allocationStatus": 0,
                "detailDTOList": [],
                "expectedQuantity": 0,
                "expectedTime": 7,
                "firmName": null,
                "remark": null,
                "rfqEndTime": null,
                "rfqNo": null,
                "rfqStartTime": null,
                "rfqStatus": 0,
                "shipToAddress": null,
                "userId": null
            }
        };
    },
    computed: {
        swiper() {
            return this.$refs.swiperTop.$swiper;
        },
    },
    watch: {
        value() {
            if(this.$parent.$parent.$parent?.getArchivesList) {
                this.$parent.$parent.$parent?.getArchivesList(1, 0);
            }
        }
    },
    methods: {
        // 获取默认地址
        async getDefaultAddress() {
            this.paramsData.shipToAddress = '';
            const res = await this.$shophttp.get('pc/userCenter/stockAddress/getDefault');
            if (res.code == 200 && res.data) {
                this.paramsData.shipToAddress = res.data.fullAddres;
            } else {
                this.$message.warning('请先设置默认收货地址');
                setTimeout(() => {
                    location.href = 'https://www.ose1525.com/user/address';
                }, 2000);
            }
        },
        // 立即下单
        async placeOrder() {
            const { remark, category } = this.priceMessage;
            // 备注
            this.paramsData.remark = remark;
            // 当前时间
            this.paramsData.rfqStartTime = new Date().Format('yyyy-MM-dd HH:mm:ss');
            this.paramsData.detailDTOList = [];
            category.forEach(item => {
                this.paramsData.detailDTOList.push(
                    {
                        "bomImg": item.img,
                        "bomName": item.name,
                        "color": null,
                        "inquiryId": null,
                        "material": null,
                        "model": null,
                        "modelUrl": null
                    });
            });
            await this.getDefaultAddress();
            // 判断默认地址是否存在
            if (this.paramsData.shipToAddress) {
                const res = await this.$shophttp.post('/inquirySheet/add', this.paramsData);
                if(res.success) {
                    this.$message.success('新增询价单成功');
                }else {
                    if(res.message) this.$message.error(res.message);
                }
            }
        },
        // 获取报价信息
        async getArchives(id) {
            const res = await getArchives(id);
            this.priceMessage = res;
            this.priceLoading = true;
            this.priceMessage.category.map((item, index) => {
                item.price = 0;
                if (this.priceMessage.category.length == (index + 1)) this.priceLoading = false
                this.amount(item, index);
            })
        },
        // 保存存档
        async putArchives() {
            await putArchives(this.priceMessage);
        },
        // 修改数量（加、减）
        count(math, data, index) {
            if (math == 'add') {
                this.priceMessage.category[index].number++;
            };
            if (math == 'sub' && this.priceMessage.category[index].number > 0) {
                this.priceMessage.category[index].number--;
            }
            this.amount(data, index);

        },
        // 计算价格
        amount(data, index) {
            let count = data.number;
            let price = 0;
            if (count > 0 && count <= data.referencePrice[0].number) {
                price = count * (data.referencePrice[0].price || 0);
            } else if (count > data.referencePrice[0].number && count < data.referencePrice[1].number) {
                price = count * (data.referencePrice[1].price || 0);
            } else if (count >= data.referencePrice[1].number) {
                price = count * (data.referencePrice[2].price || 0);
            }
            this.priceMessage.category[index].price = price - 0;

            let total = 0;
            let totalCount = 0;
            this.priceMessage.category.map((item) => {
                total += item.price;
                totalCount += (item.number - 0);
            });
            if (!this.priceLoading) {
                // 期望总数
                this.paramsData.expectedQuantity = totalCount;
                // 价格
                this.priceMessage.evaluation = total;
                this.putArchives();     
            }
        },
        // 修改标题
        changeTitle() {
            if (this.changeName) {
                this.putArchives();
                this.$message.success('修改成功');
            }
            this.changeName = false;
        }

    },
};
</script>

<style lang="less" scoped>
@import "./detailModal.less";
</style>