<template>
    <div class="address_model" :class="value && 'open'" @click.prevent.self="$emit('input', false)">
        <transition name="model">
            <div v-if="value" class="model">
                <div class="right_close">
                    <button @click="$emit('input', false)" class="close" />
                </div>
                <div class="content">
                    <slot />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            value: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="less" scoped>
.address_model {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    pointer-events: none;
    transition: all 0.3s;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.open {
        background-color: #24283e88;
        pointer-events: auto;
    }

    .model {
        overflow: auto;
        position: relative;
        max-width: 90%;
        max-height: 95%;
        min-height: 150px;
        background-color: white;
        border-radius: 10px;
        transition: all 0.3s;

        &.model-enter,
        &.model-leave-to {
            transform: translateY(100%);
            opacity: 0;
        }

        &.model-enter-to,
        &.model-leave {
            opacity: 1;
            transform: translateY(0%);
        }

        .content {
            width: 100%;
            height: 100%;
            min-height: 150px;
        }
    }

    button.close {
        all: unset;
        width: 30px;
        height: 30px;
        background-size: 60%;
        background-position: center;
        background-clip: inherit;
        background-repeat: no-repeat;
        background-image: url("~@img/icon/button_cancel.png");
        cursor: pointer;
        color: #919191;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: lighter;
        border: 1px solid #919191;
        border-radius: 8px;
    }

    .right_close {
        right: 0;
        padding: 22px 20px;
        position: absolute;
    }
}
</style>