import Vue from 'vue';

/**
* @name: 获取左上logo
* @param { type: Array }
*/
async function getLogo(params) {
    const res = await Vue.prototype.$http.get('api/tangnian3d/logo', params);
    if (!res.success) throw res.message;
    return res.data;
}

export {
    getLogo
}


// 模板模块接口
/**
* @name: 通过搜索或筛选条件获取数据列表
* @param { type: Array }
*/
async function getDataListBySearch(params) {
    const res = await Vue.prototype.$shophttp.post('tangnian3d/template/search', params);
    if (!res.success) throw res.message;
    return res.data;
}

/**
* @name: 获取查询条件
* @param { type }
* @param { type }
*/
async function getRules() {
    const res = await Vue.prototype.$http.post('api/tangnian3d/template/getRules');
    if (!res.success) throw res.message;
    return res;
}

/**
* @name: 获取搜索关键词
*/
async function getKeyword() {
    const res = await Vue.prototype.$http.get('api/tangnian3d/template/getKeyword');
    if (!res.success) throw res.message;
    return res;
}

/**
* @name: 获取模板详情
*/
async function getTemplateInfo(id) {
    const res = await Vue.prototype.$http.get(`api/tangnian3d/template/getTemplateInfo?id=${id}`);
    if (!res.success) throw res.message;
    return res.data;
}

/**
* @name: 收藏
* @param id 模板id
*/
async function collection(id) {
    // localStorage.getItem(token);
    // console.log(Vue.prototype.$http.defaults.headers[process.env.VUE_APP_Token_KEY]);
    
    const res = await Vue.prototype.$shophttp.get(`tangnian3d/template/collection?id=${id}`);
    if (!res.success) throw res.message;
    return res;
}

/**
* @name: 取消收藏
* @param id 模板id
*/
async function cancelCollection(id) {
    const res = await Vue.prototype.$shophttp.get(`tangnian3d/template/cancelCollection?id=${id}`);
    if (!res.success) throw res.message;
    return res;
}

export {
    getDataListBySearch,
    getRules,
    getKeyword,
    getTemplateInfo,
    collection,
    cancelCollection
}

// 器型（订单）模块接口
/**
* @name: 获取订单报价信息
* @param id 存档id
*/
async function getArchives(id) {
    const res = await Vue.prototype.$shophttp.get(`tangnian3d/customized/getArchives?id=${id}`);
    if (!res.success) throw res.message;
    return res.data;
}

/**
* @name: 保存（存档）报价信息
* @param data 存档信息
*/
async function putArchives(data) {
    const res = await Vue.prototype.$shophttp.post('tangnian3d/customized/putArchives', data);
    if (!res.success) throw res.message;
    return res;
}

/**
* @name: 删除（存档）报价信息
* @param id 存档id
*/
async function delArchives(id) {
    const res = await Vue.prototype.$shophttp.delete(`tangnian3d/customized/delArchives?id=${id}`);
    if (!res.success) throw res.message;
    return res;
}

/**
* @name: 下单接口
* @param id 存档id
*/
async function placeOrder(id) {
    // console.log( Vue.prototype.$http);
    // const res = await Vue.prototype.$http.post(`api/bbb/inquiryGoods/inquiryOrder`);
    // if (!res.success) throw res.message;
    // return res;
}

export {
    getArchives,
    putArchives,
    delArchives,
    placeOrder
}


// 打样模块接口
/**
* @name: 取消报价
*/
async function modifyQuotedPrice() {
    const res = await Vue.prototype.$http.get('api/tangnian3d/proofing/modifyQuotedPrice');
    if (!res.success) throw res.message;
    return res;
}

/**
* @name: 申请报价
*@param id 订单id
*/
async function applyQuotedPrice(id) {
    const res = await Vue.prototype.$http.get('api/tangnian3d/proofing/applyQuotedPrice');
    if (!res.success) throw res.message;
    return res;
}

/**
* @name: 获取存档列表
*/
async function getArchivesList(pages, state) {
    const res = await Vue.prototype.$shophttp.get(`tangnian3d/proofing/getArchivesList?pages=${pages}&state=${state}`,);
    if (!res.success) throw res.message;
    return res.data.list;
}

export {
    modifyQuotedPrice,
    applyQuotedPrice,
    getArchivesList,
}