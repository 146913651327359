<!--
 * @Author: sxw s9x9w9@163.com
 * @Date: 2022-10-27 12:30:20
 * @LastEditors: sxw s9x9w9@163.com
 * @LastEditTime: 2023-02-06 12:13:42
 * @FilePath: \goodThing\src\views\goodShade\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!-- 器型 -->
    <a-spin :spinning="spinning" size="large" tip="加载中...">
        <a-spin :spinning="loading" size="large" tip="加载中...">
            <div id="goodShade">
                <iframe ref="iframe3d" width="100%" height="100%" style="margin-top:9px;margin-left:1px;"
                    :src="`${url}?id=${id}&type=0`" frameborder="0"></iframe>
            </div>
        </a-spin>
    </a-spin>
</template>
<script>
import SelectionBar from "./components/selectionBar.vue";
import DetailModal from "./components/detailModal.vue";
import SchemeModal from "./components/schemeModal.vue";

export default {
    data() {
        return {
            open: false,
            myScheme: false,
            url: process.env.VUE_APP_3D_PROJECT,
            spin: this.$route.meta,
            spinning: false,
            id: sessionStorage?.getItem('templateId'),
            loading: true,
        }
    },
    computed: {
        meta() {
            return this.$route.meta;
        }
    },
    components: { SelectionBar, DetailModal, SchemeModal },
    watch: {
        spin: {
            handler: function () {
                this.spinning = this.spin.spin;
            },
            deep: true,
        }
    },
    mounted() {
        window.addEventListener('message', (e) => {
            if(e.data.name == 'load_completed') this.loading = false;
        })
    }
};
</script>

<style lang="less" scoped>
#goodShade {
    height: calc(100vh - 80px);
    padding: 1px 0;

    .showBtn {
        position: absolute;
        width: 100px;
        height: 50px;
        left: 40%;
        top: 200px;
        background: skyblue;
    }
}
</style>