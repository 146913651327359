<template>
    <div class="goodMould" @scroll.capture="scrollPages">
        <menu-list :shrink="shrink" :rulesList="rulesList" @checkChange="checkChange" ref="menuList"></menu-list>
        <div class="menu_content" ref="myScrollbar" :style="{ 'margin-left': shrink ? '90px' : '254px' }">
            <!-- 2023-01-16 修改 -->
            <!-- <tag-list :selectedList="selectedList" :total="total" /> -->
            <data-list ref="dataList" :loading="loading" />
        </div>
    </div>
</template>

<script>
// import { sideMenu, subList } from "./menu";
import { getDataListBySearch, getRules, getTemplateInfo } from "@/api/api.js";
import TagList from "./tagList/index.vue";
import MenuList from "./MenuList/index.vue";
import DataList from "./dataList/index.vue";
import moment from "moment";
export default {
    data() {
        return {
            shrink: false, //左侧导航栏缩放  true：收缩， false：放大
            loading: false,
            // sideMenu,
            // subList,
            open: false,
            keywordList: [],
            total: 0,
            selectedList: [],
            rulesList: [],
            meta: this.$route.meta,
            timerLoadMore: undefined,
            params: {
                search: '',
                pageNum: 1,
                date: '',
                pageSize: 30,
                recommend: false,
                keyword: '',
            }
        };
    },
    watch: {
        meta: {
            handler: function () {
                console.log(1, this.params.search, 2, this.meta.search);
                if (this.params.search != this.meta.search) {
                    this.params.search = this.meta.search;
                    this.getDataListBySearch();
                }
            },
            deep: true,
        },
    },
    components: {
        DataList,
        TagList,
        MenuList,
    },
    created() {
        this.getRules();
        this.getDataListBySearch();
    },
    methods: {

        /* 接口 */
        // 通过搜索或筛选条件获取数据列表
        async getDataListBySearch() {
            this.loading = true;
            this.params.pageNum = 1;
            const data = await getDataListBySearch(this.params);
            if (data?.content?.length) {
                sessionStorage.setItem('templateId', data.content[0].id);
            }
            this.$refs.dataList.dataList = data.content;
            this.total = data.total;
            this.$refs.dataList.waterfallList = [];
            this.$refs.dataList.dataListForWaterfall();
            this.loading = false;
        },
        // 获取查询条件
        async getRules() {
            const res = await getRules();
            // 添加展开控件开关属性
            this.rulesList = res.data;
            this.rulesList.map(item => {
                if (item.title == '好物数定推荐') {
                    this.$set(item, 'open', false);
                } else {
                    this.$set(item, 'open', true);
                }
                this.$set(this.params, item.key, '');
                this[item.key] = [];
                if (item.option) {
                    item.option.map(option => {
                        this.$set(option, 'key', item.key);
                        if (Array.isArray(option.children) && option.children?.length) {
                            this.$set(option, 'thirdOpen', false);
                            option?.children.map((child) => {
                                this.$set(child, 'key', item.key);
                            })
                        }
                    })
                }
            })
        },
        /* ------ */
        // 侧边栏缩放
        shrinkAndResize() {
            const box = document.getElementById('v-waterfall');
            this.shrink = !this.shrink;
            // 瀑布流图片动态调整宽度
            // 定时器，等左侧动画结束再开始调整，否则宽度会计算错误
            if (box.offsetWidth > 1250) {
                setTimeout(() => {
                    this.$refs.dataList.imgListIndex = 0;
                    this.$refs.dataList.waterfallList = [];
                    this.$refs.dataList.calculationWidth();
                }, 500);
            }
        },
        // 触底分页加载
        scrollPages() {
            let sh = this.$refs['myScrollbar'].scrollHeight // 滚动条高度
            let st = this.$refs['myScrollbar'].scrollTop // 滚动条距离顶部的距离
            let ch = this.$refs['myScrollbar'].clientHeight // 滚动条外容器的高度
            if (this.timerLoadMore) {
                clearTimeout(this.timerLoadMore)
            }
            const totalPage = (this.total % this.params.pageSize) == 0 ? this.total / this.params.pageSize : Math.floor(this.total / this.params.pageSize) + 1; 
            this.timerLoadMore = setTimeout(async () => { // 防抖操作
                if (st + ch >= sh) {
                    if (this.params.pageNum <= totalPage) {
                        this.params.pageNum += 1;
                        //到底了-业务逻辑
                        const data = await getDataListBySearch(this.params);
                        // if (data.content.length) {
                        this.$refs.dataList.imgList = this.$refs.dataList.imgList.concat(data.content);
                        this.$refs.dataList.imgPreloading();
                        // }
                    }
                }
            }, 300)
        },
        // 选中
        checkChange(list, date) {
            if (date) date = moment(date).format('YYYY-MM-DD');
            this.selectedList = [...list];
            this.rulesList.map(item => {
                this.params[item.key] = '';
            })
            this.selectedList.map(item => {
                this.params[item.key] += (this.params[item.key] == '' ? '' : ',') + item.value;
            })
            this.params.date = date;
            this.getDataListBySearch();
        },

    },
};
</script>

<style lang="less" scoped>
@import "~@styles/modification.less";
@import "./goodmould.less";
</style>