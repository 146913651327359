import Vue from 'vue';
import routerList from "@/views/router.js";
import NProgress from 'nprogress';													// progress bar
import 'nprogress/nprogress.css';													// progress bar style
NProgress.configure({ showSpinner: false });										// NProgress Configuration

const whiteList = routerList.filter(item => item?.meta?.access).map(item => item.path);	// 不需要重定向的页面(如登录,注册,找回密码等公共页面)
console.log(whiteList);
const loginPage = '/';																// 登录页面
const homePage = '/';																// 登录页面

export async function beforeEach(to, from, next) {

	NProgress.start();																// 开始进度条
    // sxw 20221121
	// document.title = to?.meta?.title;												// 设置页面标题

	/* 判断用户是否已登录 如果没有token */
	if (!Vue.prototype.$http.token) {
		if (whiteList.includes(to.path)) next();									// 在免登录白名单中，直接进入	
		else {
			next(loginPage);
			// next(`/login?redirect=${to.path}`);									// 其他无权访问的页面被重定向到登录页面。
			// NProgress.done();
		}
		NProgress.done();
		return;
	}

	if (to.path === loginPage) {
		next({ path: homePage });													// 如果已登录，则重定向到主页
		NProgress.done();
		return;
	} else {
		next();
	}
		

	next();
	NProgress.done();
}

export async function afterEach() {
	// finish progress bar
	NProgress.done()
}