<template>
	<component :is="type" v-bind="$attrs" v-on="$listeners" class="modal">
		<slot />
	</component>
</template>

<script>
import modal from "./modal";
import popup from "./popup";
export default {
	name: "modalBar",
	props: {
		type: {
			value: String,
			default: "modals",
		},
	},
	components: {
		modals: modal,
		popup: popup,
	},
};
</script>

<style lang="less" scoped>
</style>