<template>
	<div class="selectionBar">
		<div class="top">
			<h3 class="title">模板套件数量：<span>6</span></h3>
			<div class="content">
				<span v-for="item in countList" :key="item">{{item}}</span>
			</div>
			<h3 class="title">模板套件材质：</h3>
			<div class="content">
				<span v-for="item in textureList" :key="item">{{item}}</span>
			</div>
		</div>
		<ul class="showImage">
			<li @click="selected(index)" v-for="item, index in imageList" :key="index">
                <img :src="item.url" :alt="item.text" :class="{ selected: item.selected }">
                <span>{{item.text}}</span>
            </li>
		</ul>
	</div>
</template>

<script>
import { countList, textureList, imageList } from './selectionBar.js';
export default {
    name: 'selectionBar',
    data() {
		return {
			countList,
            textureList,
            imageList,
		};
	},
    methods: {
        selected(index) {
            this.imageList[index].selected = !this.imageList[index].selected;
            console.log(index);
        }, 
    },
};
</script>

<style lang="less" scoped>
    * {
        box-sizing: border-box
    }
    @import url("../index.less");
</style>