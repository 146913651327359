<template>
	<div class="address_model" :class="value && 'open'" @click.prevent.self="$emit('input', false)">
		<transition name="model">
			<div v-if="value" class="model">
				<!-- <button @click="$emit('input', false)" class="close"/> -->

				<div class="content">
					<slot />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			value: Boolean,
			default: false,
		},
		data: {
			value: String,
		},
	},
};
</script>

<style lang="less" scoped>
.address_model {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 10;
	pointer-events: none;
	transition: all 0.3s;
	top: 0;
	right: 0;

	&.open {
		background-color: #24283e88;

		pointer-events: auto;
	}

	// .radio_ui();

	.model {
		width: 100%;
		position: absolute;
		background-color: white;
		border-radius: 8px 8px 0 0;
		bottom: 0;
		transition: transform 0.3s;

		// &.model-enter-active, &.model-leave-active {
		// 	transition: all .3s;
		// }
		&.model-enter,
		&.model-leave-to {
			transform: translateY(100%);
		}
		&.model-enter-to,
		&.model-leave {
			transform: translateY(0%);
		}

		.content {
			width: 100%;
			height: 100%;
			min-height: 180px;
		}
	}

	button.close {
		all: unset;
		width: 48px;
		height: 48px;
		background-size: 100%;
		background-position: center;
		background-clip: inherit;
		background-repeat: no-repeat;
		margin-right: 16px;

		position: absolute;
		top: 16px;
		right: 32px;

		// background-image: url("~@image/form/close.png");
	}

	h1.title {
		font-size: 18px;
		color: black;
		margin-top: 78px;
		margin-bottom: 13px;
		text-align: center;
	}

	.list {
		flex: 1;
		overflow: auto;
		height: 280px;
		scroll-snap-type: y mandatory;
		.item {
			display: flex;
			align-items: center;
			scroll-snap-align: start;
			color: gray;
			margin: 0 32px;
			margin-bottom: 32px;
			font-size: 28px;

			input {
				margin-right: 16px;
			}
		}
	}

	button.confirm {
		all: unset;
		flex: 1;
		width: 518px;
		height: 80px;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;

		border: 1px solid;
		border-radius: 100vw;
		font-size: 32px;

		// .b-gradient;
		margin: 0 auto;
		margin-top: 20px;
		color: white;
		border: none;

		position: absolute;
		right: 0;
		left: 0;
		bottom: 88px;
	}
}
</style>