<script>
const shnCollapseElTransition =
	"0.2s height ease-in-out," +
	" 0.2s padding-top ease-in-out," +
	" 0.2s padding-bottom ease-in-out"; // 折叠元素过渡表达式

const shnCollapseTransition = {
	// 折叠元素
	"before-enter"(el) {
		el.style.transition = shnCollapseElTransition;
		if (!el.dataset) el.dataset = {};

		el.dataset.oldPaddingTop = el.style.paddingTop;
		el.dataset.oldPaddingBottom = el.style.paddingBottom;

		el.style.height = 0;
		el.style.paddingTop = 0;
		el.style.paddingBottom = 0;
	},

	enter(el) {
		el.dataset.oldOverflow = el.style.overflow;
		if (el.scrollHeight !== 0) {
			el.style.height = el.scrollHeight + "px";
			el.style.paddingTop = el.dataset.oldPaddingTop;
			el.style.paddingBottom = el.dataset.oldPaddingBottom;
		} else {
			el.style.height = "";
			el.style.paddingTop = el.dataset.oldPaddingTop;
			el.style.paddingBottom = el.dataset.oldPaddingBottom;
		}
		el.style.overflow = "hidden";
	},

	"after-enter"(el) {
		el.style.transition = "";
		el.style.height = "";
		el.style.overflow = el.dataset.oldOverflow;
	},

	"before-leave"(el) {
		if (!el.dataset) el.dataset = {};
		el.dataset.oldPaddingTop = el.style.paddingTop;
		el.dataset.oldPaddingBottom = el.style.paddingBottom;
		el.dataset.oldOverflow = el.style.overflow;

		el.style.height = el.scrollHeight + "px";
		el.style.overflow = "hidden";
	},

	leave(el) {
		if (el.scrollHeight !== 0) {
			el.style.transition = shnCollapseElTransition;
			el.style.height = 0;
			el.style.paddingTop = 0;
			el.style.paddingBottom = 0;
		}
	},

	"after-leave"(el) {
		el.style.transition = "";
		el.style.height = "";
		el.style.overflow = el.dataset.oldOverflow;
		el.style.paddingTop = el.dataset.oldPaddingTop;
		el.style.paddingBottom = el.dataset.oldPaddingBottom;
	},
};
// 组件部分
export default {
	name: "collapse",
	functional: true,
	render(createElement, { children }) {
		return createElement(
			"transition",
			{
				on: shnCollapseTransition,
			},
			children
		);
	},
};
</script>