import Vue from 'vue';
import userType from "./userType";
import throttle from "./throttle";
import space from "./space";
import copy from "./copy";
import bottom from "./bottom";

Vue.directive("copy", copy);
Vue.filter('usertype', userType);
Vue.directive("throttle", throttle);
Vue.directive("space", space);
Vue.directive("bottom", bottom);

