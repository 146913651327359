<template>
    <div>
        <!-- 扩展标签 -->
        <div class="tag_box">
            <div>您要找的是不是：</div>
            <div class="tag_list">
                <div v-for="(item, index) in tagList" :key="index">
                    <input type="checkbox" class="tag_input" :id="`tag${index}`" v-model="tagSelected"
                        @change="toSelect" :value="index">
                    <label class="tag_item" :for="`tag${index}`">{{ item }}</label>
                </div>
            </div>
        </div>
        <!-- 关键词 -->
        <div class="tag_box" style="align-items: flex-start;">
            <div class="result_box">为您找到{{ total }}个方案模板</div>
            <div class="tag_list">
                <div class="tag_item" v-for="(item, index) in selectedList" :key="index">{{ item.label }}</div>
            </div>
            <!-- <div class="tag_sort">
                <div class="tag_item">
                    <span>排序方式：</span>
                    <img :class="{ filter: $parent.waterfall }" @click="$parent.waterfall=true;$parent.$refs.dataList.calculationWidth();"
                        src="@img/icon/pxfs_pxfs_a2.png" />
                    <img :class="{ filter: !$parent.waterfall }" @click="$parent.waterfall=false;" src="@img/icon/ui_pxfs_b0.png" />
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>

import { getKeyword } from "@/api/api.js";
export default {
    props: {
        selectedList: {
            type: Array,
            default: [],
        },
        total: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            tagList: [],
            tagSelected: [],
            selected: '',
        }
    },
    mounted() {
        this.getKeyword();
    },
    methods: {
        async getKeyword() {
            const res = await getKeyword();
            // console.log(res);
            this.tagList = res.data.keyword;
        },
        toSelect() {
            this.selected = '';
            this.tagSelected.map(item => {
                this.selected += (this.selected == '' ? '' : ',') + item;
            })
            this.$parent.params.keyword = this.selected;
            this.$parent.getDataListBySearch();
        }
    },
}

</script>
<style lang='less' scoped>
@import "~@styles/modification.less";
@import "../goodmould.less";
</style>