<template>
    <modal :value="value" v-on="$listeners">

        <div class="popup_content">
            <div class="scheme_title">我的定制方案</div>
            <div class="scroll" v-if="dataList.length">
                <div v-for="scheme, schemeIndex in dataList" :key="schemeIndex" class="item">
                    <div class="scheme_content">
                        <img :src="scheme.mainImg" alt="">
                        <div class="show">
                            <p><span>{{ scheme.title || '无标题' }}</span><button>预计价格：{{ scheme?.evaluation || '暂无'
                            }}</button></p>
                            <div class="scheme_small_cover">
                                <div class="scheme_small_img">
                                    <!-- 图案展示 -->
                                    <div class="scheme_pattern">
                                        <img :src="scheme?.textureImg" alt="暂无图片" />
                                    </div>
                                    <!-- 配件列表 -->
                                    <div v-for="(item, index) in scheme.category" :key="index" class="scheme_img">
                                        <img :src="item.img" />
                                        <p :title="item.name || '无'">{{ item.name }}</p>
                                    </div>
                                </div>
                                <ul class="operation">
                                    <li class="disabled1"><button @click="toOrderDetail(scheme.id)">查看订单详情</button></li>
                                    <li><button @click="toDetail(scheme.id)">立即下单</button></li>
                                    <li><button @click.stop="toDetail(scheme.id)">修改方案</button></li>
                                    <li><button @click.stop="delArchives(scheme.id)">删除方案</button></li>
                                    <!-- <li @mouseenter="currentIndex = schemeIndex; disabled1 = true;"
                                        @mouseleave="currentIndex = null; disabled1 = false;" class="disabled1">
                                        <div @click.stop v-if="currentIndex == schemeIndex && disabled1">
                                            <div class="tip">Tips：如需修改方案，请先取消订单！</div>
                                            <div class="triangle"></div>
                                            <div class="triangle_border"></div>
                                        </div>
                                        <button @click="modifyQuotedPrice">修改方案</button>
                                    </li>
                                    <li @mouseenter="currentIndex = schemeIndex; disabled2 = true;"
                                        @mouseleave="currentIndex = null; disabled2 = false;" class="disabled2">
                                        <div @click.stop v-if="currentIndex == schemeIndex && disabled2">
                                            <div class="tip">Tips：如需删除方案，请先取消订单！</div>
                                            <div class="triangle"></div>
                                            <div class="triangle_border"></div>
                                        </div>
                                        <button @click="delArchives()">删除方案</button>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="scheme_date">{{ scheme.date.substring(0, 10) }}</div>
                </div>
                <div class="scheme_footer">目前没有更多方案了<br />...</div>
            </div>
            <div class="loading" v-if="loading && !dataList?.length">正在加载中...</div>
            <div class="empty" v-if="!dataList?.length && !loading">暂无数据</div>
            <div class="completed"><button @click="toOrderList">查看已完成订单</button></div>
            <detail-modal v-model="open" ref="detail"></detail-modal>
        </div>
    </modal>
</template>

<script>
import DetailModal from "./detailModal.vue";
import { delArchives, modifyQuotedPrice, getArchivesList, placeOrder } from "@/api/api.js";
export default {
    props: {
        value: Boolean,
    },
    components: { DetailModal },
    data() {
        return {
            currentIndex: null,
            disabled1: false,
            disabled2: false,
            dataList: [],
            loading: false,
            open: false,
        };
    },
    methods: {
        // 查看订单详情
        toDetail(id) {
            this.$refs.detail.getArchives(id);
            this.open = true;
        },
        // 删除存档
        async delArchives(id) {
            this.loading = true;
            const res = await delArchives(id);
            if (res.sucess) {
                this.$message.success('删除成功');
                await this.getArchivesList(1, 0);
                this.loading = false;
            }
            
        },
        // 取消报价
        async modifyQuotedPrice() {
            const res = await modifyQuotedPrice(1);
            // console.log(res);
        },
        // 获取存档列表
        async getArchivesList(pages, state) {
            this.loading = true;
            const res = await getArchivesList(pages, state);
            this.dataList = res;
            this.loading = false;
            // console.log('存档列表', res);
        },
        // 查看订单列表
        toOrderList() {
            const token = localStorage.getItem(process.env.VUE_APP_Token_KEY);
            if (token) {
                window.open('https://www.ose1525.com/user/order/enquiryList');
            } else {
                this.$message.warning('请先登录，即将跳转登录页');
                setTimeout(() => {
                    location.href = "https://www.ose1525.com/login?source=https://1525ose.com";
                }, 2000)
            }
            
        },
        // 查看订单详情
        toOrderDetail(id) {
            const token = localStorage.getItem(process.env.VUE_APP_Token_KEY);
            if (token) {
                window.open(`https://www.ose1525.com/user/order/detail?tradeId=${id}`);
            } else {
                this.$message.warning('请先登录，即将跳转登录页');
                setTimeout(() => {
                    location.href = "https://www.ose1525.com/login?source=https://1525ose.com";
                }, 2000)
            }
            
        }
    }
};
</script>

<style lang="less" scoped>
@import url('./schemeModal.less');
</style>