<template>
    <div id="app">
        <a-config-provider :locale="locale">
            <router-view />
        </a-config-provider>
    </div>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
    data() {
        return { locale: zh_CN }
    },
}
</script>
<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;

    width: 100%;
    height: 100%;
    img {
        -webkit-user-drag:none;
    }
}
</style>
