<template>
	<div class="side-bar">
		<div v-for="route in routeList" :key="route.name" :class="{ active: hasName(route.name) }" class="nav-item-block">
			<div @click="$router.push({ name: route.name })" class="nav-item">
				<img :src="hasName(route.name) ? route.meta.activeIcon : route.meta.icon" alt="icon" srcset />
				<h1>{{ route.meta.title }}</h1>
			</div>
		</div>
	</div>
</template>

<script>
// import path from 'path';
// import { getDataListBySearch } from '@/api/api.js';
import path from "path-browserify";
export default {
	data() {
		return {
			path,
			open: true,
		};
	},
	computed: {
		routeList() {
            // console.log(this.$router);
			return this.$router.options.routes[0].children.find(route => route.path == "layout")?.children || [];
		},
	},
	methods: {
		hasName(...args) {
			try {
				const pathList = args.filter(item => item);
				// console.log(pathList);
				const pathStr = path.join(...args);
				return pathStr == this.$route.name;

			} catch (error) {
				// console.log(error);
			}
		},
        // async toRoute(route) {
        //     this.$router.push(route.path);
        //     if(route.name == 'GoodShade') {
        //         const res = await getDataListBySearch({pageNum: 1});
        //         const id = res?.content[0]?.id;
        //         sessionStorage.setItem('templateId', id);
        //     }
        // }
	},
	mounted() {
		window.path = path;
	},
};
</script>

<style lang="less" scoped>
.side-bar {

	width: 54px;
	flex: 0 0 54px;
    height: calc(100vh - 130px);
    // 2023-01-16 修改
    margin-top: 10px;
    background-color: #FFF;
	// height: 963px;

	padding:  37px 0 0 0;

	display: flex;
	flex-direction: column;
	overflow: hidden;

	border-radius: 4px 0 0 4px;
	// box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);


	.nav-item-block {
		// min-height: 100px;
		// padding: 22px;
		transition: all 0.3s;
		margin-bottom: 35px;


		.nav-item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;

			cursor: pointer;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 0;
                height: 100%;
                border-left: 5px solid rgba(0, 0, 0, 0);
                // transition: all .5s;
            }

			img {
				width: 25px;
				flex: 0 0 25px;
				height: 25px;
			}

			h1 {
				margin: 0;

				width: 100%;
				font-size: 12px;
				font-family: MiSans;
				font-weight: 400;
				text-align: center;
				color: #333333;
				// line-height: 12px;
			}
		}
        &.active .nav-item::before {
            border-left: 5px solid #AE1E23;
            // transition: height .5s;
            // height: 100%;
            // transform: translateY(80px);
        }

		&.active h1{
			color: #ae1d23;
		}

		&:hover h1, &:hover img{
			transform: scale(1.1);
		}
	}
}
</style>