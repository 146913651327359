import Vue from 'vue';
import axios from 'axios';

const myAxios = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: process.VUE_APP_TIME_OUT,
    headers: {
        // 'Authorization': 
        [process.env.VUE_APP_Token_KEY]: '',
    }

});
const shopAxios = axios.create({
    baseURL: process.env.VUE_APP_SHOP_BASE_API,
    timeout: process.VUE_APP_TIME_OUT,
    headers: {
        // 'Authorization': 
        [process.env.VUE_APP_Token_KEY]: '',
    }

});
// 登陆token
const authorize = new URLSearchParams(location.search);
const token = authorize.get('token');
if (token) {
    localStorage.setItem(process.env.VUE_APP_Token_KEY, token);
    shopAxios.defaults.headers[process.env.VUE_APP_Token_KEY] = token;
} else {
    shopAxios.defaults.headers[process.env.VUE_APP_Token_KEY] = localStorage.getItem(process.env.VUE_APP_Token_KEY, token);
}


// 请求拦截器
// myAxios.interceptors.request.use(

// 	error => {
// 		console.log(error) // for debug
// 		return Promise.reject(error)
// 	}
// );

// 添加响应拦截器
myAxios.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        if (response.status == 200) return response.data;
    },
    error => {
        if (error.response) { // 请求已发出，服务器以状态码响应并超出 2xx 的范围
            if (error.response.status == 401 || error.response.status == 403) {
                Vue.prototype.$message.error('登录失效，即将跳转登录界面');
                localStorage.removeItem(process.env.VUE_APP_Token_KEY);
                setTimeout(() => {
                    location.href = 'https://www.ose1525.com/login?source=https://1525ose.com';
                }, 2000);
            };
            // if()
        } else if (error.request) { // 已提出请求但未收到响应 `error.request` 是浏览器中的 XMLHttpRequest 实例和 node.js 中的 http.ClientRequest 实例
            throw Error(error.message);
        } else { // 设置触发错误的请求时发生了一些事情
            throw Error("网络异常，请检查网络!");
        }
        return Promise.reject(response);
    });
// 添加响应拦截器
shopAxios.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        if (response.data.code == 300) {
            Vue.prototype.$message.error('登录失效，即将跳转登录界面');
            localStorage.removeItem(process.env.VUE_APP_Token_KEY);
            setTimeout(() => {
                location.href = 'https://www.ose1525.com/login?source=https://1525ose.com';
            }, 2000);
        };
        if (response.status == 200) return response.data;
    },
    error => {
        if (error.response) { // 请求已发出，服务器以状态码响应并超出 2xx 的范围
            if (error.response.status == 401) {
                Vue.prototype.$message.error('登录失效，即将跳转登录界面');
                setTimeout(() => {
                    location.href = 'https://www.ose1525.com/login?source=https://1525ose.com';
                }, 2000);
            };
            // if()
        } else if (error.request) {
            throw Error(error.message);
        } else { // 设置触发错误的请求时发生了一些事情
            throw Error("网络异常，请检查网络!");
        }
        return Promise.reject(response);
    });


Vue.prototype.$http = myAxios;
Vue.prototype.$shophttp = shopAxios;