<template>
    <div class="view">
        <TitleBar>
            <components :is="$route.name" />
        </TitleBar>
        <div class="container">
            <NavBar />
            <AppMain />
        </div>
        <div class="fixedkf chat_btn" accountid="124" company="124" title="客服">
            <img src="@img/icon/ui_kf.png" alt>
        </div>
    </div>
</template>
<script>
import { NavBar, TitleBar, AppMain } from "./components/index.js";
import GoodMould from "./TitleMenu/GoodMould.vue";
import GoodShade from "./TitleMenu/GoodShade.vue";
import loadJs from './kefu.js';
export default {
    name: 'Layout',
    data() {
        return {
            // data
        }
    },
    mounted() {
        loadJs('https://workbench.daokf.cn/kf/customer/daodao_kf');
        this.$forceUpdate();
    },
    methods: {
        // methods
    },
    components: {
        NavBar,
        TitleBar,
        AppMain,
        GoodMould,
        GoodShade,
    }
}
</script>

<style lang="less" scoped>
.view {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 25px 0px 30px;


    .container {
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: 10px;
        background-color: #F2F2F2;
        // overflow: hidden;

    }

    .navigation-block {
        width: 54px;
        height: 963px;

        .item {
            width: 18px;
            height: 9px;
            font-size: 9px;
            font-family: MiSans;
            font-weight: 400;
            color: #AE1E23;
            line-height: 12px;
        }
    }



    .fixedkf {
        background: #FFF;
        border-radius: 50%;
        padding: 20px;
        width: 75px;
        height: 75px;
        position: fixed;
        right: 50px;
        bottom: 100px;
        box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
        z-index: 999;
        cursor: pointer;

        img {
            width: 100%;

            &:hover {
                scale: 1.1;
            }
        }
    }
}
</style>