<template>
    <div class="home-show">
        <div class="show-content">
            <img class="show_a" src="@img/show_a.png" alt="1">
            <img class="show_b" src="@img/show_b.png" alt="2">
            <img @click="$router.push({ path: 'layout' })" class="show_c" src="@img/show_c.png" alt="">
        </div>
        <!-- <img class="show_reference first_show" src="@img/show_ba.png" alt="备案号"> -->
        <div class="show_reference first_show">©2019-2021 1525ose.com 版权所有 | 网站备案号：沪ICP备19046667号-2</div>
    </div>
</template>

<script>
    export default {
        mounted() {
            setTimeout(() => {
                this.$router.push({ path: 'layout' });
            }, 5000);
        }
    }
</script>

<style lang='less' scoped>
div.home-show {
    position: relative;
    min-width: 1920px;
    height: 100%;
    background-color: #D9002E;
    overflow: hidden;
    padding-bottom: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

div.show-content {
    display: flex;
    align-items: center;

    >img {
        height: 160px;
    }

    .show_a {
        margin-right: 30px;
        animation: icon_show 0.6s linear 0.8s;
        animation-fill-mode: both;
    }

    .show_b {
        margin-right: 60px;
        animation: icon_show 0.5s linear 1.6s;
        animation-fill-mode: both;
    }

    .show_c {
        height: 180px;
        animation: third_show 0.6s linear 2.1s, infinite_show 1.9s linear 2.7s infinite;
        animation-fill-mode: both;
        cursor: pointer;
    }
}

div.show_reference {
    position: absolute;
    bottom: 30px;

    height: auto;
    color: #FFF;
    animation: icon_show 0.6s linear 0.8s;
    animation-fill-mode: both;

}

@keyframes icon_show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes third_show {
    0% {
        scale: 0.01;
    }

    60% {
        scale: 1;
    }

    80% {
        scale: 1.2;
    }

    100% {
        scale: 1;
    }
}

@keyframes infinite_show {
    55% {
        scale: 1;
    }

    60% {
        scale: 1.2;
    }

    63% {
        scale: 1;
    }

    73% {
        scale: 0;
    }

    80% {
        scale: 1;
    }

    85% {
        scale: 1.2;
    }

    90% {
        scale: 1;
    }
}
</style>