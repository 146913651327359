<template>
    <div class="menu_list" :style="{ width: shrink ? '70px' : '234px' }">
        <!-- 筛选 -->
        <div class="menu_item menu_filter">
            <div class="menu_title">
                <div>
                    <img :style="{ 'margin-right': shrink ? '0' : '12px' }" class="menu_icon" src="@img/icon/ui_sx.png"
                        alt />
                    <span class="side_text" :class="{ shrink: shrink }">筛选</span>
                </div>
                <div class="menu_right">
                    <img @click="sideShrink" :class="{ rotate: shrink }" class="filter_arrow"
                        src="@img/icon/ui_arrow_sx.png" alt />
                </div>
            </div>
        </div>
        <!-- 一级下拉 -->
        <div v-for="(item, index) in rulesList" :key="item.key" class="menu_item">
            <!-- 展示框 -->
            <div class="menu_title" @click="openList(item)" style="padding-bottom: 15px;">
                <div>
                    <img class="menu_icon" :style="{ 'margin-right': shrink ? '0' : '12px' }"
                        :src="item.icon || require('@img/icon/ui_dzpzl.png')" />
                    <span :class="{ shrink: shrink, selected: !item?.option?.length && item.open }" class="side_text">{{
                            item.title
                    }}</span>
                    <!-- 提示框 -->
                    <div style="position:relative" v-if="item.tip" @mouseenter="currentIndex = index;"
                        @mouseleave="currentIndex = null;">
                        <div class="tip" @click.stop v-if="currentIndex == index">{{ item.tip }}</div>
                        <div class="triangle" @click.stop v-if="currentIndex == index"></div>
                        <div class="triangle_border" @click.stop v-if="currentIndex == index"></div>
                        <img :class="{ shrink: shrink }" class="menu_tipIcon" src="@img/icon/ui_i.png" />
                    </div>
                </div>
                <div class="menu_right" v-if="item?.option?.length">
                    <!-- 右侧展开图标 -->
                    <img :class="{ open: item.open }" src="@img/icon/ui_arrow_down.png" class="arrow" alt="arrow"
                        srcset />
                </div>
            </div>
            <!-- 二级下拉 -->
            <collapse>
                <div v-if="item.open && item?.option?.length">
                    <!-- 下拉列表 -->
                    <div class="subList">
                        <div class="menu_third_out" v-for="(subitem, subItemIndex) in item?.option" :key="subItemIndex">
                            <!-- 无三级下拉的展示框 -->
                            <!-- 2023-01-16 解除判断项 v-if="!subitem?.children?.length" -->
                            <div class="subitem" style="padding-bottom:15px;">
                                <input class="item-checkbox" type="checkbox" name="mode"
                                    :style="{ 'margin-right': shrink ? '0' : '4px' }" :value="subitem"
                                    v-model="selectedList" @change="checkChange" :id="`id_${subitem.value}`" />
                                <label :for="`id_${subitem.value}`">{{ subitem.label }}</label>
                            </div>
                            <!-- 有三级下拉的展示框 -->
                            <!-- 2023-01-16 大改 布局与逻辑 -->
                            <!-- <div v-else :class="{ menu_third: subitem?.children?.length }"
                                :style="{ 'padding-left': shrink ? '0px' : '30px' }">
                                <div class="menu_title" @click="subitem.thirdOpen = !subitem.thirdOpen">
                                    <span>{{ subitem.label }}</span>
                                    <div class="menu_right">
                                        <img :class="{ open: subitem.thirdOpen }" src="@img/icon/ui_arrow_down.png"
                                            class="arrow" alt="arrow" srcset />
                                    </div>
                                </div>
                                <collapse>
                                    <div v-if="subitem.thirdOpen">
                                        <div class="subitem subitem_third" v-for="data, dataIndex in subitem.children"
                                            :key="dataIndex">
                                            <input class="item-checkbox third_radio" type="checkbox" name="mode"
                                                :style="{ 'margin-right': shrink ? '0' : '4px' }" :value="data"
                                                v-model="selectedList" @change="checkChange" :id="`id_${data.value}_`"/>
                                            <label :for="`id_${data.value}_`">{{ data.label }}</label>
                                        </div>
                                    </div>
                                </collapse>
                            </div> -->
                        </div>
                    </div>
                </div>
            </collapse>
        </div>
        <div class="menu_item">
            <div class="menu_title">
                <div>
                    <img class="menu_icon" :style="{ 'margin-right': shrink ? '0' : '12px' }"
                        src="~@img/icon/ui_fbrq.png" />
                    <span :class="{ shrink: shrink }" class="side_text">发布日期</span>
                </div>
            </div>
            <div class="menu_input">
                <a-date-picker :placeholder="shrink ? '' : '请选择日期'" v-model="date" @change="checkChange" />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        shrink: {
            type: Boolean,
            default: false,
        },
        rulesList: {
            type: Array,
            default: [],
        }
    },
    data() {
        return {
            currentIndex: null,
            selectedList: [],
            date: '',
        }
    },
    methods: {
        // 左侧栏缩放
        sideShrink() {
            this.$parent.shrinkAndResize();
        },
        // 选中
        checkChange() {
            this.$emit('checkChange', this.selectedList, this.date);
        },
        // 打开列表
        openList(item) {
            item.open = !item.open;
            if (!item?.option?.length) {
                this.recommend();
            }
        },
        // 好物数定推荐
        recommend() {
            this.$parent.params.recommend = !this.$parent.params.recommend;
            this.$parent.getDataListBySearch();
        }
    }
}

</script>
<style lang='less' scoped>
@import "~@styles/modification.less";
@import "../goodmould.less";

.shrink {
    width: 0 !important;
    overflow: hidden;
    transition: all .5s;
}

.side_text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all .5s;
}

.rotate {
    transform: rotate(-180deg);
    transition: all .3s;
}

.selected {
    color: red;
}

/deep/ .ant-calendar-picker-icon {
    display: none;
}
</style>