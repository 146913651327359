<template>
    <div class="title-block-menu">
        <div class="search-div">
            <input v-model="search" type="text" class="search-input" placeholder="每个关键词之间用 ',' 隔开"
                @keyup.enter="toSearch">
            <button class="search-btn menu-btn position-search" @click="toSearch">
                <img src="~@img/icon/ui_search.png" alt="">
            </button>
        </div>
        <button @click="toUserInfo" class="user-btn menu-btn">
            <img src="~@img/icon/ui_man.png" alt="">
        </button>
        <button class="store-btn menu-btn" @click="lookScheme">
            <img src="~@img/icon/ui_bag.png" alt="">
        </button>
        <scheme-modal v-model="myScheme" ref="myScheme" />
    </div>
</template>

<script>
import SchemeModal from '@/views/goodShade/components/schemeModal.vue';
export default {
    name: "About",
    components: { SchemeModal },
    data() {
        return {
            myScheme: false,
            search: '',
            tempmeta: this.$route.meta,
            tempsearch: null,
        }
    },
    watch: {
        tempmeta: {
            handler(value) {
                // if (value.tempsearch != this.tempsearch) {
                // this.tempsearch = value.tempsearch;
                this.search = value.search;
                // }
            },
            deep: true,
        }
    },
    methods: {
        // 搜索
        toSearch() {
            console.log(this.search);
            this.$route.meta.search = this.search;
        },
        // 查看存档
        lookScheme() {
            const token = localStorage.getItem(process.env.VUE_APP_Token_KEY);
            if (token) {
                this.$refs.myScheme.getArchivesList(1, 0);
                this.myScheme = true;
            } else {
                this.$message.warning('请先登录，即将跳转登录页');
                setTimeout(() => {
                    location.href = "https://www.ose1525.com/login?source=https://1525ose.com";
                }, 2000)
            }
        },
        // 跳转个人中心
        toUserInfo() {
            const token = localStorage.getItem(process.env.VUE_APP_Token_KEY);
            if (token) {
                window.open('https://www.ose1525.com/user/info');
            } else {
                this.$message.warning('请先登录，即将跳转登录页');
                setTimeout(() => {
                    location.href = "https://www.ose1525.com/login?source=https://1525ose.com";
                }, 2000)
            }

        }
    }
};
</script>

<style lang="less" scoped>
.title-block-menu {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    padding: 10px;

    div.search-div {
        width: 100%;
        height: 100%;
        position: relative;

        &::before {
            content: '搜索';
            position: absolute;
            top: 7px;
            left: 15px;
            font-size: 15px;
        }
    }

    input.search-input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding: 0 55px;
        font-size: 14px;
        background: #E6E6E6;
        border-radius: 20px;
        // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1) inset;
        margin-right: 22px;

        &::placeholder {
            color: #BDBDBD;
            font-size: 12px;
        }
    }

    button.position-search {
        width: 20px !important;
        position: absolute;
        top: 2px;
        right: 20px;
    }

    button.menu-btn {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        margin-left: 25px;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        transition: all .3 ease-in-out;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &:active {
            opacity: 0.5;
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}
</style>