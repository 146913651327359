import GoodMould from './GoodMould/index.vue'
import GoodShade from './goodShade/index.vue'

export default [
	// GoodMould,// 
	{
		path: '/',
		name: "GoodMould",
		component: GoodMould,
		meta: {
			title: '模版',
			access: true,
			icon: 'icon/ui_mb.png',
			activeIcon: 'icon/ui_mb_r.png',
            search:'',
            tempsearch: ''
		},
	},
	{
		path: '/goodShade',
		name: 'GoodShade',
		component: GoodShade,
		meta: {
			title: '器型',
			access: true,
			icon: 'icon/ui_qx.png',
			activeIcon: 'icon/ui_qx_r.png',
            id: '',
            spin: false,
		},
	},
]