<template>
  <transition name="block_box">
    <a-spin :spinning="loading" size="large" tip="加载中...">
      <div id="block_box" class="block_box">
        <div class="good_list" id="v-waterfall">
          <div
            @click="toRoute(item)"
            class="good_item animation_good_item"
            v-for="(item, index) in waterfallList"
            :key="index"
            :style="{ top: item.top + 'px', left: item.left + 'px', width: waterfallImgWidth + 'px', height: item.height }"
          >
            <img :src="item.image || require('@img/home/歼-20.png')" />
            <!-- 遮罩层 -->
            <div class="good_item_shade">
              <div class="good_introduce">
                {{ item?.introduction || "" }}
              </div>
              <div class="good_operation">
                <!-- 找相似 -->
                <img src="@img/ui_search_similar.png" @click.stop="getSimilar(item.id)" alt="" />
                <!-- 收藏 -->
                <img v-if="item?.isCollection" src="@img/ui_heart.png" @click.stop="collection(item, index)" alt="" />
                <img v-else src="@img/ui_heart_0.png" alt="" @click.stop="collection(item, index)" />
              </div>
            </div>
            <!-- 2023-01-16 修改 -->
            <!-- <div class="good_detail">
                        <div class="good_instrouce">
                            <p class="good_title">{{ item.title }}</p>
                            <p class="good_subtitle">{{ item?.introduction }}</p>
                        </div>
                        <div class="good_button">
                            <button class="good_collect"
                                :class="{ collected: item?.isCollection, discollect: !item?.isCollection }"
                                @click="collection(item, index)"><span>{{
                                        item?.isCollection ? '已收藏' :
                                            '收藏'
                                }}</span></button>
                            <button class="good_made" @click="goDetail(item)">进入定制</button>
                        </div>
                    </div> -->
          </div>
        </div>
        <div v-if="!dataList.length" style="margin-top: 200px; display: flex; justify-content: center; align-items: center; font-size: 50px">
          暂无数据
        </div>
        <!-- 商品详情 -->
        <find-similar v-model="open" ref="FindSimilar"></find-similar>
      </div>
    </a-spin>
  </transition>
</template>

<script>
import FindSimilar from "../FindSimilar";
import { getTemplateInfo, collection, cancelCollection } from "@/api/api.js";
export default {
    props: {
        loading: Boolean
    },
    data() {
        return {
            open: false,
            waterfallList: [],
            // waterfallImgWidth: 496, // 每个盒子的宽度
            waterfallImgCol: 5, // 瀑布流的列数
            waterfallImgRight: 10, // 每个盒子的右padding
            waterfallImgBottom: 10, // 每个盒子的下padding
            waterfallDeviationHeight: [],
            imgList: [],
            dataList: [],
            imgListIndex: 0,
        };
    },
    components: {
        FindSimilar,
    },
    methods: {
        // 找相似
        async getSimilar(id) {
            const res = await getTemplateInfo(id);
            if (res.style) {
                const styleVal = this.$parent.$refs.menuList._props.rulesList[0].option;
                styleVal.map(item => {
                    if (item.label == res.style) {
                        this.$refs.FindSimilar.params.style = item.value;
                        this.open = true;
                        this.$refs.FindSimilar.getSimilarList();
                    }
                })
            } else {
                this.$message.warning('无相似模板');
            }

        },
        // 触发入口
        dataListForWaterfall() {
            for (let i = 0; i < this.dataList.length; i++) {
                this.imgList.push(this.dataList[i]);
            }
            this.calculationWidth();
        },
        //计算每个图片的宽度或者是列数
        calculationWidth() {
            // let domWidth = document.getElementById("v-waterfall").offsetWidth;
            // if (!this.waterfallImgWidth && this.waterfallImgCol) {
            //     this.waterfallImgWidth =
            //         (domWidth - this.waterfallImgRight * this.waterfallImgCol) /
            //         this.waterfallImgCol;
            // } else if (this.waterfallImgWidth && !this.waterfallImgCol) {
            //     this.waterfallImgCol = Math.floor(
            //         domWidth / (this.waterfallImgWidth + this.waterfallImgRight)
            //     );
            // }
            // console.log("this.waterfallImgCol", this.waterfallImgCol);
            //初始化偏移高度数组
            this.waterfallDeviationHeight = new Array(this.waterfallImgCol);

            for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
                this.waterfallDeviationHeight[i] = 0;
            }
            this.imgPreloading();
        },
        //图片预加载
        imgPreloading() {
            // this.waterfallList = [];
            const width = document.getElementById('v-waterfall').offsetWidth - 60;
            this.waterfallImgWidth = Math.floor(width / this.waterfallImgCol);
            // (1595 - 100) / 5
            if (this.waterfallImgWidth < 310) this.waterfallImgWidth = 310;
            for (let i = this.imgListIndex; i < this.imgList.length; i++) {
                let aImg = new Image();
                aImg.src = this.imgList[i].image || require('@img/home/歼-20.png');
                aImg.onload = aImg.onerror = (e) => {
                    let imgData = {};
                    imgData = this.imgList[i];
                    imgData.height = (this.waterfallImgWidth / aImg.width) * aImg.height;
                    this.waterfallList.push(imgData);
                    this.rankImg(imgData);
                };
            }
            this.imgListIndex = this.imgList.length;
        },
        //瀑布流布局
        rankImg(imgData) {
            let {
                waterfallImgWidth,
                waterfallImgRight,
                waterfallImgBottom,
                waterfallDeviationHeight,
                waterfallImgCol,
            } = this;
            let minIndex = this.filterMin();
            imgData.top = waterfallDeviationHeight[minIndex];
            imgData.left = minIndex * (waterfallImgRight + waterfallImgWidth);
            // 2023-01-16 修改
            // waterfallDeviationHeight[minIndex] += imgData.height + waterfallImgBottom + 120; // 加了文字的盒子高度，留出文字的地方（这里设置56px）
            waterfallDeviationHeight[minIndex] += imgData.height + waterfallImgBottom;
        },
        /**
         * 找到最短的列并返回下标
         * @returns {number} 下标
         */
        filterMin() {
            const min = Math.min.apply(null, this.waterfallDeviationHeight);
            return this.waterfallDeviationHeight.indexOf(min);
        },
        // 收藏
        async collection(data, index) {
            const token = localStorage.getItem(process.env.VUE_APP_Token_KEY);
            if (token) {
                try {
                    let res = null;
                    if (data.isCollection) {
                        res = await cancelCollection(data.id);
                    } else {
                        res = await collection(data.id);
                    }
                    if (res.success) {
                        this.waterfallList[index].isCollection = !this.waterfallList[index].isCollection;
                        this.$message.success(res.message);
                    } else {
                        if(res.message) this.$message.error(res.message);
                    }
                } catch(err) {
                    this.$message.error(err);
                }

            } else {
                this.$message.warning('请先登录，即将跳转登录页');
                setTimeout(() => {
                    location.href = "https://www.ose1525.com/login?source=https://1525ose.com";
                }, 2000)
            }
        },
        // 跳转3D
        toRoute(item) {
            this.$router.push('/goodShade');
            sessionStorage.setItem('templateId', item?.id);
        }
    },
};
</script>

<style lang="less" scoped>
@import "./dataList.less";
</style>
