export default {
	bind: (el, binding) => {
		el.addEventListener('blur', event => {
			let target = event.target;
			target.value = event.target.value.replace(/(^\s*)|(\s*$)/g, "");
			target.dispatchEvent(new Event('input'));
			target.dispatchEvent(new Event('change'));
		}, true);
	},
}
