<template>
	<section class="app-main">
		<transition name="fade-transform" mode="out-in">
			<router-view :key="key"  />
		</transition>
	</section>
</template>

<script>
export default {
	name: 'AppMain',
	methods: {
		// methods

		emitEvent({ name = 'event', data = undefined }) {
			this.$emit(name, data);
		},
	},
	computed: {
		key() {
			return this.$route.path
		}
	}
}
</script>

<style scoped>
.app-main {
	/*50 = navbar	*/
	flex: 1;
	/* min-height: calc(100vh - 50px); */
	position: relative;
	overflow: hidden;
}
.fixed-header+.app-main {
	padding-top: 60px;
}
</style>

<style lang="less">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
	.fixed-header {
		padding-right: 15px;
	}
}
</style>