<template>
    <div class="title-block-menu">

        <div class="menu-group">
            <!-- 保存 -->
            <button class="search-btn menu-btn" @click="saveScheme" title="保存">
                <img src="~@img/icon/ui_save.png" alt>
            </button>
            <!-- 回退 -->
            <button class="user-btn menu-btn" @click="undoEdit" title="上一步">
                <img :class="{ filter: undo }" src="~@img/icon/ui_arrow_search.png" alt>
            </button>
            <!-- 重做 -->
            <button class="user-btn menu-btn" @click="redoEdit" title="下一步">
                <img :class="{ filter: redo }" src="~@img/icon/ui_arrow_search2.png" alt>
            </button>
        </div>


        <div class="menu-group">
            <input type="text" class="search-input" v-model="title">
            <button class="search-submit" @click="rename">重命名</button>
        </div>

        <div class="menu-group">
            <button class="menu-btn">
                <img src="~@img/icon/ui_share.png" alt="">
            </button>
            <span>分享</span>
            <button class="menu-btn" @click="lookDetail">
                <img src="~@img/icon/ui_print.png" alt="">
            </button>
            <span class="draw-design">打样</span>
        </div>



        <!-- <input type="text" class="search-input"> -->

        <div class="menu-group">
            <button class="user-btn menu-btn" @click="toUserInfo()">
                <img src="~@img/icon/ui_man.png" alt="">
            </button>
            <button class="store-btn menu-btn" @click="lookScheme()" title="存档列表">
                <img src="~@img/icon/ui_bag.png" alt="">
            </button>

        </div>
        <detail-modal v-model="open" ref="detail" />
        <scheme-modal v-model="myScheme" ref="myScheme" />

    </div>
</template>

<script>
import DetailModal from "@/views/goodShade/components/detailModal.vue";
import SchemeModal from "@/views/goodShade/components/schemeModal.vue";
import { getArchives, putArchives } from "@/api/api.js";
export default {
    name: "About",
    data() {
        return {
            open: false,
            myScheme: false,
            undo: 0,
            redo: 0,
            id: null,
            title: '',
        }
    },
    mounted() {
        // this.$route.meta.spin = false;
        window.onmessage = async (e) => {
            const name = e.data.name;
            const count = e.data.data;
            this.id = e.data.id;
            if (name == 'undo_edit_number') this.undo = count;
            if (name == 'redo_edit_number') this.redo = count;
            if (name == 'proofing_end') {
                console.log('count', count);
                const res = await putArchives(count);
                this.$route.meta.spin = false;
                this.open = true;
                this.$refs?.detail?.getArchives(res.data);
            }
            if (name == 'save_complete') {
                this.$route.meta.spin = false;
                this.$message.success('保存成功');
            }
            if (name == 'change_template') {
                this.$router.push('/layout');
            }
            if (name == 'set_title') this.title = e.data.title;
            if (name == 'save_end')  {
                await putArchives(count);
                this.$route.meta.spin = false;
            }
        };
    },
    methods: {
        // 打样
        lookDetail() {
            const token = localStorage.getItem(process.env.VUE_APP_Token_KEY);
            if (token) {
                const data = {
                    name: 'proofing_start'
                };
                window.frames[0].postMessage(data, '*');
                this.$route.meta.spin = true;
            } else {
                this.$message.warning('请先登录，即将跳转登录页');
                setTimeout(() => {
                    location.href = "https://www.ose1525.com/login?source=https://1525ose.com";
                }, 2000)
            }

        },
        // 保存存档
        saveScheme() {
            const token = localStorage.getItem(process.env.VUE_APP_Token_KEY);
            if (token) {
            
                const data = {
                    name: 'save_data'
                };
                window.frames[0].postMessage(data, '*');
                this.$route.meta.spin = true;
            } else {
                this.$message.warning('请先登录，即将跳转登录页');
                setTimeout(() => {
                    location.href = "https://www.ose1525.com/login?source=https://1525ose.com";
                }, 2000)
            }
        },
        // 查看
        async getArchives(id) {
            const res = await getArchives(id);
            console.log(res);
            this.title = res.title;
        },
        // 提交
        async putArchives(data) {
            const res = await putArchives(data);
            console.log(res);
        },
        // 回退
        undoEdit() {
            if (this.undo > 0) {
                const data = {
                    name: 'undo_edit'
                };
                window.frames[0].postMessage(data, '*');
            }
        },
        // 重做
        redoEdit() {
            if (this.redo > 0) {
                const data = {
                    name: 'redo_edit'
                };
                window.frames[0].postMessage(data, '*');
            }
        },
        // 重命名
        rename() {
            if (this.title) {
                const data = {
                    name: 'rename_archives',
                    title: this.title,
                };
                window.frames[0].postMessage(data, '*');
                this.title = '';
                this.$message.success('重命名成功');
            } else {
                this.$message.warning('请输入标题')
            }
        },
        // 查看存档
        lookScheme() {
            const token = localStorage.getItem(process.env.VUE_APP_Token_KEY);
            if (token) {
                this.$refs.myScheme.getArchivesList(1, 0);
                this.myScheme = true;
            } else {
                this.$message.warning('请先登录，即将跳转登录页');
                setTimeout(() => {
                    location.href = "https://www.ose1525.com/login?source=https://1525ose.com";
                }, 2000)
            }
        },
        // 跳转个人中心
        toUserInfo() {
            const token = localStorage.getItem(process.env.VUE_APP_Token_KEY);
            if (token) {
                window.open('https://www.ose1525.com/user/info');
            } else {
                this.$message.warning('请先登录，即将跳转登录页');
                setTimeout(() => {
                    location.href = "https://www.ose1525.com/login?source=https://1525ose.com";
                }, 2000)
            }

        }
    },
    components: { DetailModal, SchemeModal },
};
</script>

<style lang="less" scoped>
.title-block-menu {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    input.search-input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding: 0 15px;
        font-size: 14px;
        background: #E6E6E6;
        border-radius: 20px;
        // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1) inset;
        margin-right: 22px;

        &::placeholder {
            color: #BDBDBD;
            font-size: 12px;
        }
    }

    button.search-submit {
        white-space: nowrap;
        width: 80px;
        height: 70%;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        transition: all .3 ease-in-out;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: rgba(0, 0, 0, 0.7);

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menu-group {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;

        >span {
            white-space: nowrap;
        }

        .draw-design {
            &::after {
                content: '一个起订';
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 0;
                top: -3px;
                right: -50px;
                position: absolute;
                width: 60px;
                height: 22px;
                z-index: 2;
                font-size: 13px;
                color: #FFF;
                background: #AE1E23;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: scale(0.75);
            }
        }
    }


    button.menu-btn {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        margin-left: 10px;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        margin-left: 25px;
        transition: all .3 ease-in-out;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &:active {
            opacity: 0.5;
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}

.filter {
    filter: brightness(50%);
}
</style>