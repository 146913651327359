<template>
    <modal :value="value" v-on="$listeners">
        <div class="popup_content">
            <!-- 列表 -->
            <div @click="toRoute(item)" class="imgList" v-for="(item, index) in dataList" :key="index">
                <img :src="item.image">
                <div class="good_item_shade">
                    <div class="good_introduce">
                        {{ item?.introduction || '暂无介绍' }}
                    </div>
                    <div class="good_operation">
                        <!-- 收藏 -->
                        <img v-if="item?.isCollection" src="@img/ui_heart.png" @click.stop="collection(item, index)"
                            alt="">
                        <img v-else src="@img/ui_heart_0.png" alt="" @click.stop="collection(item, index)">
                    </div>
                </div>
            </div>

            <!-- 换一批 -->
            <div class="changeList" @click="changeList">
                <img src="@img/icon/change.png" alt="换一批">
                <span>换一批</span>
            </div>
        </div>
    </modal>
</template>

<script>
import { getDataListBySearch, collection, cancelCollection } from "@/api/api.js";
export default {
    props: {
        value: Boolean,
    },
    data() {
        return {
            open: true,
            params: {
                pageNum: 1,
                pageSize: 5,
                recommend: false,
                style: '',
            },
            dataList: [],
            total: 0,
        };
    },
    computed: {
        totalPage() {
            return (this.total % this.params.pageSize) == 0 ? this.total / this.params.pageSize : Math.floor(this.total / this.params.pageSize) + 1;
        }
    },
    wathch: {
        open() {
            this.params.pageNum = 1;
        }
    },
    methods: {
        //获取相似模板列表
        async getSimilarList() {
            const res = await getDataListBySearch(this.params);
            console.log(res);
            this.dataList = res.content;
            this.total = res.total;
        },
        changeList() {
            console.log(this.totalPage);
            console.log(this.params.pageNum);
            if (this.params.pageNum < this.totalPage) {
                this.params.pageNum++;
            } else {
                this.params.pageNum = 1;
                this.$message.warning('没有更多了');
            }
            this.getSimilarList();
        },
        // 收藏
        async collection(data, index) {
            let res = null;
            try {
                if (data.isCollection) {
                    res = await cancelCollection(data.id);
                } else {
                    res = await collection(data.id);
                }
                if (res.success) {
                    this.waterfallList[index].isCollection = !this.waterfallList[index].isCollection;
                    this.$message.success(res.message);
                } else {
                    if(res.message) this.$message.error(res.message);
                }
            } catch (error) {
                if(error) this.$message.error(error);
            }
        },
        // 跳转3D
        toRoute(item) {
            this.$router.push('/goodShade');
            sessionStorage.setItem('templateId', item?.id);
        },

    },
};
</script>

<style lang="less" scoped>
@import url('./findSimilar.less');
</style>