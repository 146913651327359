import Vue from 'vue'
import VueRouter from 'vue-router';
import Layout from '../layout/index.vue';
import view from '../views/router.js';
import HomeShow from '../views/HomeShow.vue';
import App from '../App.vue';
// import GoodMould from '../views/GoodMould/index.vue'
// import GoodShade from '../views/goodShade/index.vue'

import { beforeEach, afterEach } from './permission'; // 权限控制

Vue.use(VueRouter);


// console.log("views", views);

const routes = [
    {
        path: '/',
        component: App,
        children: [
            {
                path: '/',
                name: 'HomeShow',
                component: HomeShow,
                meta: {
                    access: true,
                }
            },
            {
                path: 'layout',
                // name: 'Layout',
                children: view,
                component: Layout,
                meta: {
                    access: true,
                }
            }
        ]
    }

    // {
    //     path: '/goodShade',
    //     name: 'GoodShade',
    //     component: GoodShade,
    // },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

// router.beforeEach(beforeEach);
// router.afterEach(afterEach);

export default router;
