function loadJs(src) {
    return new Promise((resolve,reject)=>{
      let script = document.createElement('script');
      script.type = "text/javascript";
      console.log(src);
      script.src= src;
      document.body.appendChild(script);
        
      script.onload = ()=>{
        resolve();
      }
      script.onerror = ()=>{
        reject();
      }
    })
  }
   
  export default loadJs