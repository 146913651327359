export default {
	bind(el, { value }) {
		return;
		el.$value = value
		el.handler = () => {
			const data = el.$value ? !el.$value : el.innerText;
			// 动态创建 textarea 标签
			const textarea = document.createElement('textarea')
			// 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
			textarea.readOnly = 'readonly'
			textarea.style.position = 'absolute'
			textarea.style.left = '-9999px'
			// 将要 copy 的值赋给 textarea 标签的 value 属性
			textarea.value = data;
			// 将 textarea 插入到 body 中
			document.body.appendChild(textarea)
			// 选中值并复制
			textarea.select()
			const result = document.execCommand('Copy');
			document.body.removeChild(textarea);
			if (result) {
				console.log('复制成功') // 可根据项目UI设计

				document.body.dispatchEvent(new Event("popup"));

				// this.showToask({
				// 	type:'popup',
				// 	content: String('复制成功')
				// });
			}
		}
		// 绑定点击事件，就是所谓的一键 copy 啦
		el.addEventListener('click', el.handler)
	},
	// 当传进来的值更新的时候触发
	componentUpdated(el, { value }) {
		el.$value = value
	},
	// 指令与元素解绑的时候，移除事件绑定
	unbind(el) {
		el.removeEventListener('click', el.handler)
	},
}