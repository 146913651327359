export const countList = ["纸巾盒", "浴盐盅", "皂碟", "垃圾桶", "毛巾托盒", "卫生用品盒"];
export const textureList = ["树脂", "喷漆", "金属铸件"];
export const imageList = [
    {
        url: require('@img/shade/model_wsyph.png'),
        text: '卫生用品盒',
        selected: false,
    },
    {
        url: require('@img/shade/model_zjh.png'),
        text: '纸巾盒',
        selected: false,
    },
    {
        url: require('@img/shade/model_mjtp.png'),
        text: '毛巾托盘',
        selected: false,
    },
    {
        url: require('@img/shade/model_yyz.png'),
        text: '浴盐盅',
        selected: false,
    },
    {
        url: require('@img/shade/model_zd.png'),
        text: '皂碟',
        selected: false,
    },
    {
        url: require('@img/shade/model_ljt.png'),
        text: '垃圾桶',
        selected: false,
    },
];