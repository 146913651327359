

export default {
	bind(el, { value }) {
		let pressTimer = null;// 计时器索引变量
		function start(e) {// 定义函数处理程序
			if (pressTimer === null) {
				pressTimer = setTimeout(() => pressTimer = null, 1000);// ios的橡皮筋会触发多次 scroll 事件
				if (value instanceof Function) value();// 执行函数
				this.dispatchEvent(new Event("popup"));
			}
		}

		el.addEventListener("scroll", function onScroll(e) {
			let scrollTop = e.target.scrollTop;
			let scrollHeight = e.target.scrollHeight;
			let offsetHeight = Math.ceil(e.target.getBoundingClientRect().height);
			let currentHeight = scrollTop + offsetHeight;

			if (currentHeight >= scrollHeight) start();
		})
		
	},
}