export default function(x) {

	console.log(x);

	// 示例：admin 运营审核 subscribe 订阅号管理员 editor 订阅号编者
	let userName = new Map([
		['admin', '运营审核'],
		['subscribe', '订阅号管理员'],
		['editor', '订阅号编者']
	]);


	return userName.get(x) // + "." + decimal;
}